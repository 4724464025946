export enum DataHook {
  WidgetWrapper = 'Profile-Wrapper',
  EditProfileButton = 'Profile-EditProfileButton',
  LikesReceivedStats = 'Profile-LikesReceivedStats',
  CommentsStats = 'Profile-CommentsStats',
  TopCommentsStats = 'Profile-TopCommentsStats',
  CreatePostLink = 'Profile-CreatePostLink',
  WriteYourFirstPostLink = 'Profile-WriteYourFirstPostLink',
  Post = 'Profile-Post',
  PostsLoader = 'Profile-PostsLoader',
  LoadMorePostsButton = 'Profile-LoadMorePostsButton',
}
